import React from 'react';
import styled, { keyframes } from 'styled-components';
import Diamond from '../logoss.png';

const pulse = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LoaderImage = styled.img`
  width: 100px;
  height: 100px;
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

const Loader = () => {
  return (
    <LoaderWrapper>
      <LoaderImage src={Diamond} alt="Loading" />
    </LoaderWrapper>
  );
};

export default Loader;