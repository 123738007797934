import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import "./App.css"
import ScrollToTop from './components/ScrollToTop';
import Loader from './components/Loader';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

const TravelForm = lazy(() => import('./components/TravelForm'));
const UserData = lazy(() => import('./components/UserData'));
const UnauthorizedPage = lazy(() => import('./components/UnauthorizedPage'));
const ConvertCustomer = lazy(() => import('./components/ConvertCustomer'));
const JustDial = lazy(() => import('./components/JustDial'));
const Auth = lazy(() => import('./components/Auth'));
const NotAccess = lazy(() => import('./components/NotAccess'));
const RoleAssignment = lazy(() => import('./components/RoleAssignment '));

function AppContent() {
  const [user, setUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [accessibleRoutes, setAccessibleRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch('https://tarvelbuddychatbot.lm.r.appspot.com/api/check-auth', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (data.authorized) {
            setUser(data.user);
            setIsAuthorized(true);
            setIsAdmin(data.isAdmin);
            setAccessibleRoutes(data.accessibleRoutes || []); // Provide a default empty array
          } else {
            // If not authorized, redirect to auth page
            handleLogout();
          }
        } catch (error) {
          console.error('Error checking authorization:', error);
          handleLogout();
        }
      } else {
        // If no token, redirect to auth page
        handleLogout();
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setIsAuthorized(false);
    setIsAdmin(false);
    setAccessibleRoutes([]);
    navigate('/auth');
  };

  if (loading) {
    return <Loader />;
  }

  if (isAuthorized && (!user || user.role === null || user.role === 'null')) {
    return <NotAccess />;
  }

  return (
    <>
      {isAuthorized && user && user.role !== 'null' && (
        <Navbar bg="light" expand="lg" className="shadow-sm">
          <Navbar.Brand href="/" className="fw-bold text-primary mx-3">
            Travel Buddy
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              {accessibleRoutes.includes('home') && <Nav.Link href="/">Home</Nav.Link>}
              {accessibleRoutes.includes('traveler-info') && <Nav.Link href="/user-data">Traveler Info</Nav.Link>}
              {accessibleRoutes.includes('justdial-leads') && <Nav.Link href="/justdial-leads">JustDial Leads</Nav.Link>}
              {accessibleRoutes.includes('role-assignment') && <Nav.Link href="/role-assignment">Role Assignment</Nav.Link>}
            </Nav>
            <Nav className="ms-auto me-3">
              <Nav.Link onClick={handleLogout} className="text-danger">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}

      <Suspense fallback={<Loader />}>
        <Routes>
          <Route 
            path="/" 
            element={
              accessibleRoutes.includes('home')
                ? <TravelForm user={user} />
                : <Navigate to="/auth" />
            } 
          />
          <Route 
            path="/user-data" 
            element={
              accessibleRoutes.includes('traveler-info')
                ? <UserData user={user} /> 
                : <UnauthorizedPage />
            } 
          />
          <Route 
            path="/auth" 
            element={
              isAuthorized ? (
                <Navigate to="/" />
              ) : (
                <Auth
                  setUser={setUser}
                  setIsAuthorized={setIsAuthorized}
                  setIsAdmin={setIsAdmin}
                  setAccessibleRoutes={setAccessibleRoutes}
                />
              )
            } 
          />
          <Route 
            path="/convert-customer" 
            element={
              accessibleRoutes.includes('convert-customer')
                ? <ConvertCustomer /> 
                : <UnauthorizedPage />
            } 
          />
          <Route 
            path="/justdial-leads" 
            element={
              accessibleRoutes.includes('justdial-leads')
                ? <JustDial /> 
                : <UnauthorizedPage />
            } 
          />
          <Route 
            path="/role-assignment" 
            element={
              accessibleRoutes.includes('role-assignment')
                ? <RoleAssignment /> 
                : <UnauthorizedPage />
            } 
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>

      {isAuthorized && user && user.role !== 'null' && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppContent />
    </Router>
  );
}

export default App;